<template>
  <div class="container-fluid bg-white p-5 rounded-lg">
    <div class="row mb-3">
      <div class="col-12 px-0">
        <ModalActions
          mode="edit"
          @close="$router.push({ name: 'visions' })"
          @show="$router.push({ name: 'show-vision' })"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12 p-0 d-flex flex-wrap justify-content-between">
        <span class="h2 text-secondary"
          ><i class="mdi mdi-rocket"></i> Modifier vision</span
        >
        <div>
          <b-button pill>Confirmer</b-button>
        </div>
      </div>
    </div>

    <div class="row my-4">
      <b-card class="col-md-8 col-12 shdow p-0">
        <div class="w-100 m-0 p-0 text-secondary">
          <p class="h4 mb-4">
            <i class="mdi mdi-timer-sand"></i> Vision stratégique
          </p>
        </div>
        <div class="row m-0 p-0">
          <div class="col-sm-6 m-0 p-0 pr-2">
            <input type="text" class="form-control" placeholder="Libellé" />
          </div>
          <div class="col-sm-6 m-0 p-0">
            <!-- <select class="custom-select" id="inputGroupSelect02">
              <option selected>Responsable</option>
              <option value="1">Responsable 1</option>
              <option value="2">Responsable 2</option>
              <option value="3">Responsable 3</option>
            </select> -->
            <v-select :options="responsables" placeholder="Responsable" />
          </div>
        </div>
        <div class="row m-0 p-0 mt-3">
          <div class="col-sm-6 m-0 p-0 pr-2">
            <textarea
              class="form-control h-100"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Description"
            ></textarea>
          </div>
          <div class="col-sm-6 m-0 p-0">
            <div class="mb-2">
              <input
                type="text"
                class="form-control"
                placeholder="Organisation Responsable"
              />
            </div>
            <b-form-datepicker
              :readonly="true"
              :value="new Date()"
              disabled
              placeholder="Date de début"
            ></b-form-datepicker>
          </div>
        </div>
        <div class="row p-0 m-0 mt-3">
          <div class="col-sm-6 m-0 p-0 pr-2">
            <!-- <select class="custom-select" id="inputGroupSelect02">
              <option selected>Statut</option>
              <option value="1">Statut 1</option>
              <option value="2">Statut 2</option>
              <option value="3">Statut 3</option>
            </select> -->
            <v-select
              :options="statusVisions"
              class="style-chooser"
              placeholder="Statut"
            />
          </div>
          <div class="col-sm-6 m-0 p-0">
            <b-form-datepicker placeholder="Date de fin"></b-form-datepicker>
          </div>
        </div>
      </b-card>

      <div
        class="col-12 col-md-4 d-flex flex-column justify-content-between p-0 mt-3 mt-md-0"
      >
        <div class="shdow ml-md-4 mb-2">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="w-100 m-0 p-0 text-secondary">
              <p class="h4 mb-0">
                <i class="mdi mdi-speedometer"></i> Avancement
              </p>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-12 m-0 p-0">
                <b-form-datepicker
                  class="mb-2"
                  :readonly="true"
                  :value="new Date()"
                  disabled
                  placeholder="Date de début"
                ></b-form-datepicker>
                <b-form-datepicker
                  class="mb-2"
                  placeholder="Date de fin"
                ></b-form-datepicker>
                <simple-slider />
              </div>
            </div>
          </div>
        </div>

        <div style="flex: 1" class="shdow ml-md-4">
          <div class="w-100 m-0 p-0 text-secondary">
            <p class="h4 mb-4"><i class="mdi mdi-file"></i> Couverture</p>
          </div>
          <div class="row m-0 p-0">
            <div
              class="col-12 d-flex justify-content-between align-items-center mb-3 px-0"
            >
              <span class="mb-0 d-inline-block">Couverture</span>
              <b-button variant="light" pill size="xs"
                ><i class="mdi mdi-plus"></i
              ></b-button>
            </div>
          </div>
          <div class="row m-0 p-0">
            <div
              style="height: 50px; position: relative; overflow: visible"
              class="shdow"
            >
              <p><country-flag country="it" size="small" /> Italie</p>
              <div style="position: absolute; top: -5px; right: -5px">
                <span style="cursor: pointer"
                  ><i class="mdi mdi-close-circle text-danger"></i
                ></span>
              </div>
            </div>
            <div
              style="height: 50px; position: relative; overflow: visible"
              class="shdow"
            >
              <p><country-flag country="fr" size="small" /> France</p>
              <div style="position: absolute; top: -5px; right: -5px">
                <span style="cursor: pointer"
                  ><i class="mdi mdi-close-circle text-danger"></i
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-tabs class="tab-solid tab-solid-primary mt-3">
      <b-tab title="Objectifs stratégiques">
        <div class="row p-0 m-0 justify-content-end">
          <!-- <div
            style="border: 1px solid gray; border-radius: 20px"
            class="row p-2 m-0 align-items-center"
          >
            <i class="mdi mdi-plus-circle"></i>
            <span class="ml-2">Ajouter un objectif stratégique</span>
          </div> -->

          <b-button
            variant="secondary"
            class="align-btn-table"
            @click="addEditableStrategicGoal"
            >+ Ajouter un objectif stratégique</b-button
          >
        </div>
        <strategic-goal-list
          @showFdrListModal="showFdrList = !showFdrList"
          @removeEditableStrategicGoal="removeEditableStrategicGoal"
          :items="strategicGoal.tableItems"
          :fields="strategicGoal.tableFields"
        />
      </b-tab>
      <b-tab title="Cartographie de déploiement" lazy>
        <div><MapBox /></div>
      </b-tab>
    </b-tabs>

    <div v-if="showFdrList" to="modals-xyz-500">
      <sub-modal @close="showFdrList = !showFdrList">
        <fdr-list />
      </sub-modal>
    </div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import SimpleSlider from "@/components/forms/sliders/simpleSlider.vue";
import ModalActions from "@/components/custom/ModalActions";
import StrategicGoalList from "./components/StrategicGoalList.vue";
import SubModal from "../../../components/custom/SubModal.vue";
import FdrList from "./components/FdrList.vue";
import MapBox from "@/components/custom/MapBox.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    CountryFlag,
    SimpleSlider,
    ModalActions,
    StrategicGoalList,
    SubModal,
    FdrList,
    MapBox,
  },
  data: () => ({
    strategicGoal: {
      tableItems: [
        {
          strategic_goal: "Objectif strategique 1",
          description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
          organisation_responsable: "DAF4",
          pays: "Egypt",
          responsable: {
            name: "S.E",
            full_name: "Steve Ewore",
            img: "https://source.unsplash.com/random/150x150",
          },
          kpis: "RE6SQPO0",
          progress: "80",
          alignment: "4",
          actions: ["edit", "delete"],
        },
        {
          editMode: true,
        },
      ],
      tableFields: [
        {
          key: "strategic_goal",
          label: "Objectif stratégique",
          sortable: true,
        },
        { key: "description", label: "Description", sortable: true },
        {
          key: "organisation_responsable",
          label: "Organisation",
          sortable: true,
        },
        // { key: "country", label: "Pays", sortable: true },
        { key: "responsable", label: "Responsable", sortable: true },
        { key: "kpis", label: "KPIs", sortable: true },
        // { key: "progress", label: "Avancement", sortable: true },
        // { key: "alignment", label: "Alignement", sortable: true },
        { key: "actions", label: "", sortable: false },
      ],
    },
    bubblesConfig: {
      data: [
        {
          name: "Area 1",
          radius: 5,
          centered: "BRA",
        },
        {
          name: "Area 2",
          radius: 5,
          centered: "USA",
        },
        {
          name: "Area 4",
          radius: 5,
          centered: "RUS",
        },
        {
          name: "Area 5",
          radius: 5,
          centered: "AUS",
        },
      ],
      highlightBorderColor: "#FFFFFF",
      highlightFillColor: "#F8A900",
    },
    geographyConfig: {
      popupOnHover: false,
      highlightOnHover: false,
      borderWidth: 0,
    },
    data: {
      USA: { fillKey: "active" },
      RUS: { fillKey: "active" },
      AUS: { fillKey: "active" },
      BRA: { fillKey: "active" },
    },
    fills: {
      defaultFill: "#3B3D46",
      active: "#F8A900",
    },
    showFdrList: false,
  }),
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchVisionStatus");
    this.$store.dispatch("status/fetchAllStatus");
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("status", ["VISIONS_STATUS", "STATUS"]),
    responsables() {
      return this.RESPONSABLES.map((resp) => {
        return resp.firstname + " " + resp.lastname;
      });
    },
    statusVisions() {
      return this.VISIONS_STATUS;
    },
  },

  methods: {
    addEditableStrategicGoal() {
      this.strategicGoal.tableItems.push({ editMode: true });
    },
    removeEditableStrategicGoal(itemIndex) {
      this.strategicGoal.tableItems = this.strategicGoal.tableItems.filter(
        (item, index) => index !== itemIndex
      );
    },
  },
};
</script>

<style scoped></style>

<style>
.map {
  padding-top: 42%;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #ffffff;
  border: none;
  color: #9b9b9b;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__dropdown-toggle {
  height: 44px;
  width: auto;
  border: 1px rgb(212, 212, 212) solid;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #000000;
}
</style>
