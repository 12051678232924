<template>
  <div class="modal-xyz-500">
    <div
      class="w-100 h-100"
      @click="$emit('close')"
      style="position: fixed; top: 0; left: 0"
    ></div>
    <div class="sub-modal">
      <div v-if="!noHeaders" class="sb_mdl_ctrls">
        <div
          class="mdl_btn"
          v-show="action === 'show'"
          @click="
            $emit('close');
            $emit('printItem', item);
          "
        >
          <i style="color: white; cursor: pointer" class="mdi mdi-printer"></i>
        </div>
        <div class="mdl_btn" v-show="item">
          <i
            style="color: white; cursor: pointer"
            @click="
              $emit('close');
              $emit('editItem', item);
            "
            class="mdi mdi-pencil-outline"
          ></i>
        </div>
        <b-button
          @click="$emit('close')"
          class="d-flex align-items-center justify-content-center"
          variant="primary"
          pill
          size="sm"
          style="height: 35px; width: 35px"
          ><i class="mdi mdi-close"></i
        ></b-button>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "SubModal",
  props: {
    noHeaders: Boolean,
    item: Object,
    action: String,
  },
};
</script>

<style scoped>
.modal-xyz-500 {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.377);
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  /* transform: translate(-50%, -50%); */
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.sub-modal {
  background-color: white;
  padding: 20px 40px;
  border-radius: 10px;
  min-width: 800px;
  max-width: 800px;
  max-height: 80%;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (max-width: 990px) {
  .sub-modal {
    min-width: 80%;
    max-width: 80%;
  }
}

.sb_mdl_ctrls {
  /* position: absolute; */
  /* top: -45px; */
  /* background-color: rebeccapurple; */
  height: 40px;
  /* right: 0px; */
  /* width: 100px; */
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
}

.mdl_btn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
}
</style>
